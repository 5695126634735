.login-bg {
  /*   background-image: url("../../assets/images/loginBG.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.login-wrap {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.content {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.content h1 {
  font-size: 64px;
}

.content h4 {
  font-size: 24px;
}

.logo-wrap {
  max-width: 250px;
  margin: 0 auto;
  justify-content: center;
}

.font-24 {
  font-size: 24px;
}

.forgot-wrap {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 0 auto;
}



.pass-info {
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 24px;
  width: 300px;
  /*   height: 150px; */
  margin-top: 0px;
  position: absolute;
  /* transform: translate(172%, 7%); */
  top: 0;
  right: -320px;
}

.pass-info h5 {
  font-weight: 700;
}

.pass-info p {
  background: url(../images/blueTick.svg) no-repeat left;
  padding-left: 20px;
  height: 16px;
  display: block;
  font-size: 14px;
}

.message-box {
  background: rgba(217, 217, 217, 0.2);
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
}

.message-box h4 {
  font-size: 18px;
  margin-top: 0;
}

.message-box p {
  margin-bottom: 0;
}

.message-box .flex-shrink-0 {
  align-items: center;
  display: flex;
}

.image-icon {
  cursor: pointer;
}