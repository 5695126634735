.list-wrapper-sidebar {
    display: flex;
    justify-content: left;
    margin-top: 0px;
    height: calc(100vh - 195px);
    overflow-y: auto;
}

.list-content-sidebar {
    width: 21%;
}

.list-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list-content ul li {
    margin: 0;
    padding: 24px 0 14px 0;
    list-style: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.list-content ul li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 26px;
    background: url('../../assets/images/right-arrow.svg') no-repeat right;
    background-size: 32px;
}

.list-content ul li a:hover {
    text-decoration: none;
}

.list-content-sidebar ul li {
    margin: 0;
    padding: 0px 0 0px 0;
    list-style: none;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
    border-bottom: 1px solid rgba(0, 0, 0, 0.65);
}

.list-content-sidebar ul li a {
    background: none;
    padding: 10px;
}

.user-profile-left {
    right: 80%;
}

.logout-icon-left {
    position: fixed;
    top: 95px;
    left: 49px;
}

.link-active {
    width: 100%;
    border: 2px solid #0A84FF;
    margin: 0px;
    color: #0A84FF;
}

.user-profile-device {
    /* width: 96px;
    height: 96px;
    border-radius: 48px;
    left: 20px;
    position: fixed;
    overflow: hidden; */
    display: inline-block;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    left: 30px;
    position: fixed;
    overflow: hidden;
}

.add-space {
    padding-top: 45%;
}

.add-space-logout {
    margin: 77px;
}

.corner-screen {
    position: fixed;
    bottom: 0;
    left: 0.5%;
}

.corner-screen-icon {
    position: relative;
    top: 65%;
    right: 9%;
}