@font-face {
  font-family: "SF-Pro-DisplayRegular";
  src: url("../fonts/SFPRODISPLAYMEDIUM.woff");
  src: url("../fonts/SFPRODISPLAYREGULAR.ttf") format("truetype");
}

@font-face {
  font-family: "SF-Pro-DisplayMedium";
  src: url("../fonts/SFPRODISPLAYMEDIUM.woff");
  src: url("../fonts/SFPRODISPLAYMEDIUM.ttf") format("truetype");
}

:root {
  --toastify-icon-color-success: #0a84ff !important;
  --toastify-color-progress-success: #0a84ff !important;
}

/*html{
  font-size:100%;
}
 @media (max-width: 1440px){
  html{
    font-size: 62.5%;
  }
} */
body {
  background-color: #000;
  font-family: "SF-Pro-DisplayMedium", "SF-Pro-DisplayRegular", "OpenSans", Arial, sans-serif;
  font-size: 20px;
  color: #fff;
}

h3 {
  font-size: 32px;
  color: #fff;
  margin: 0;
}

a,
a:hover {
  color: rgb(255, 255, 255);
  cursor: pointer;
}

label {
  margin-bottom: 0;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.hidediv {
  display: none !important;
}

.font-medium {
  font-family: "SF-Pro-DisplayMedium";
}

.container-fluid {
  padding: 0;
  position: relative;
  /*  max-width: 1420px;	 */
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.wrapper {
  position: relative;
  width: 100vw;
  display: flex;
  height: 100vh;
  color: #fff;
}

.container-wrap {
  display: flex;
  width: 100%;
  padding: 24px;
}

.btn {
  padding: 20px;
}

.range-slider {
  width: 100%;
  margin: 0px 0 0 0%;
}

.range-slider__range {
  width: calc(100% - (73px));
  height: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.15);
  outline: none;
  padding: 0;
  margin: 0;
}

.range-slider__value {
  display: inline-block;
  position: relative;
  width: 60px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: transparent;
  padding: 5px 10px;
  margin-left: 8px;
  border: 1px solid rgba(255, 255, 255, 0.25);
}

input[type="range"] {
  display: inline;
  width: auto;
}

.btn-primary-conform,
.btn-primary-conform:hover {
  background-color: #fff;
  border-color: #fff;
  color: #000;
  font-size: 18px;
  min-width: 150px;
  height: 50px;
  padding: 0 16px;
  border-radius: 8px;
}

.btn-secondary {
  background-color: #0A84FF;
  background: #0A84FF;
  border-color: #0A84FF;
  color: #fff;
  font-size: 18px;
  min-width: 150px;
  height: 50px;
  padding: 0 16px;
  border-radius: 4px;
}

.btn-secondary-outline {
  background-color: none;
  background: none;
  border-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  font-size: 18px;
  min-width: 150px;
  height: 50px;
  padding: 0 16px;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
}

.btn-secondary-outline:hover {
  border-color: #0A84FF;
  color: #fff;
}

.mr-2 {
  margin-right: 32px;
}

.mr-4 {
  margin-right: 64px;
}

.ml-1 {
  margin-left: 16px !important;
}

.ml-2 {
  margin-left: 32px;
}

.ml-loader {
  right: 15%;
  position: relative;
}

.ml-4 {
  margin-left: 64px;
}

.mt-4 {
  margin-top: 32px !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.mb-1 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 48px !important;
}

.my-5 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.w-75 {
  width: 75%;
  margin: 0 auto;
}

.w-50 {
  width: 50%;
  margin: 0 auto;
}

.dpw-50 {
  width: 50% !important;
}

/* input style + focus */
.field-holder {
  position: relative;
  margin-bottom: 35px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.field-holder>.inputText {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  min-height: 64px;
  padding-top: 32px;
  padding-right: 46px;
}

/* .field-holder .input-group-text{
  background-color: none;
  background: none;
  /* border: 1px solid rgba(255, 255, 255, 0.15); 
  border-left: none;
  margin-left: -2px;
  position: absolute;
  right: 0.5rem;
  top: 0.65rem;
}

.cus-row .input-group-text{
  background-color: none;
  background: none;
  /* border: 1px solid rgba(255, 255, 255, 0.15); 
  border-left: none;
  margin-left: -2px;
  position: absolute;
  right: 19.5rem;
} */

.field-holder .input-group-text {
  background-color: none;
  background: none;
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
  border-left: none;
  margin-left: -2px;
  position: absolute;
  right: 8px;
  top: 20px;
}

.inputText {
  display: block;
  width: 100%;
  padding: 4px 14px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  height: auto;
  background-color: none;
  background: none;
  padding-left: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  outline: none;
}

.inputText:focus {
  border: 1px solid #0A84FF;
}

.inputText:focus~.floating-label {
  top: 16px;
  color: #0A84FF;
}

.no-error-input .inputTextNoFocusWithValue {
  top: 16px !important;
  color: #0A84FF !important;
}

.error-input .inputTextNoFocusWithValue {
  top: 16px !important;
  color: #FF4040 !important;
}

.error-dropdown {
  color: #FF4040 !important;
}

.error-dropdown .error {
  float: left;
  margin-left: 10px;
  margin-top: 5px;
}

.error-dropdown .dropdown-toggle,
.error-dropdown .dropdown-toggle:focus,
.error-dropdown .dropdown-toggle:hover {
  border: 1px solid #FF4040;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 24px;
  transition: 0.3s ease all;
  color: #666;
  font-size: 14px;
  text-transform: uppercase;
}

/* .form-control:focus ~ .form-label,
.form-control:not(:focus):valid ~ .form-label{
  color: #0A84FF;
  font-size: 1rem;
  top: 0.65rem;
  left: 1rem;
  pointer-events: none;
} */
/* error message inputbox */

.error-input .inputText {
  border-color: #FF4040;
}

/* .error-input .inputText:focus{
  border: none;
} */
.error-input .floating-label,
.error-input input:focus~.floating-label {
  color: #FF4040;
}

.error-msg {
  color: #FF4040;
  position: absolute;
  bottom: -30px;
  left: 12px;
}

/* error message end */

.btn-signin {
  background-color: #fff;
  color: #000;
  width: 180px;
  height: 60px;
  padding: 0;
  /* margin-top: 1.5rem; */
  font-size: 24px;
}

.text-underline {
  text-decoration: underline !important;
}

/* loader - animation */
.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .85);
  height: 100%;
  position: fixed;
  z-index: 1020;
  top: 0;
  width: 100%;
  left: 24%;
}

.circle-border {
  width: 64px;
  height: 67px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../images/loader-icon.png') no-repeat center center;
  /* animation: spin .8s linear 0s infinite; */
  position: relative;
  right: 14%;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

/* custom toggle switch */
.form-check {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 18px;
  padding-left: 24px;
  margin-bottom: 2px;
}

.form-switch {
  padding-left: 40px;
}

.auth-option {
  float: right;
}

.auth-option:checked {
  background-color: #0A84FF;
  border-color: #0A84FF;
}

.form-check-input:checked {
  background-color: #0A84FF;
  border-color: #0A84FF;
}

.form-switch .form-check-input {
  width: 48px;
  margin-left: -40px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 32px;
  -webkit-transition: background-position .15s ease-in-out;
  transition: background-position .15s ease-in-out;
}

.form-check .form-check-input {
  float: left;
  margin-left: -24px;
}

.form-check-input {
  width: 16px;
  height: 32px;
  margin-top: 0;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.form-switch .form-check-input:checked {
  background-color: #0A84FF;
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.switch-label-right {
  padding-right: 64px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.4);
  font-size: 16px;
  font-weight: 500;
}

.switch-label-left {
  font-size: 16px;
  margin-left: 24px;
  margin-bottom: 0;
}

/*.right-details{
  background: url('../images/right-arrow-icon.svg') no-repeat center center;
  width: 3.2rem;
  height: 3.2rem;
  display: block;
  margin-left: 2rem;
}
*/

.cus-col-50 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  width: 50%;
  float: left;
}

.w-100 {
  width: 100%;
  padding: 0px 24px;
}

.fade.show {
  opacity: 1 !important;
}

.close {
  color: #fff;
  /* text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20); */
  opacity: 1;
}

/* Drop Down  */

.btn-drop-down {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  font-size: 18px;
  min-width: 100%;
  height: 70px;
}

.btn-drop-down:hover,
.btn-drop-down:focus,
.btn-drop-down:active,
.btn-drop-down:hover:active,
.btn-drop-down:focus:active {
  border-color: #0A84FF;
  color: #fff;
  background-color: #000;
  min-width: 100%;
  font-size: 18px;
  height: 70px;
}

.btn-primary:hover,
.btn-primary:focus:active {
  border-color: #0A84FF;
  color: #fff;
  background-color: #000;
  font-size: 18px;
  height: 70px;
}

.dropdown-menu {
  width: 100%;
  background-color: #000;
  font-size: 18px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin: 0;
  padding: 0;
}

.dropdown-menu>a.dropdown-item {
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  line-height: 2;
  width: 100%;
  display: block;
  border-bottom: 1px solid;
  padding: 16px 12px;
  background-color: #000;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.dropdown-menu>a.dropdown-item:hover,
.dropdown-menu>a.dropdown-item:focus {
  color: rgba(255, 255, 255);
  text-decoration: none;
  background-color: rgba(0, 0, 0, .25);
}

.dropdown-scroll {
  min-height: 50px;
  max-height: 300px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.dropdown-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.dropdown-scroll::-webkit-scrollbar-thumb {
  background-color: #0A84FF;
}

.caret {
  background: url('../../assets/images/drop-down-Icons.svg') no-repeat;
  width: 26px;
  height: 26px;
  border: none;
}

.hg-theme-default .hg-button span {
  color: black;
}

/* Modal dialog */
.modal-dialog {
  width: 723px;
}

.modal-backdrop {
  left: 23% !important;
}

.modal {
  left: 20% !important;
}

.modal-dialog-centered {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-content {
  background-color: #151515;
  width: 100%;
  padding: 20px;
  border-radius: 24px;
  max-width: 800px;
}

.modal-backdrop.fade {
  opacity: .9 !important;
}

.modal.fade .modal-dialog {
  transform: none;
}