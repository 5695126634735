.position-fixed {
  position: fixed;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.main-content {
  overflow-x: auto;
  height: 100%;
}

.h-100 {
  height: 100%;
}

/* sidebar */
.side-bar {
  height: 100vh;
  display: flex;
  align-items: center;
  /*   justify-content: space-between; */
  flex-direction: column;
  padding: 32px 0;
  /* border-right: 1px solid rgba(255, 255, 255, 0.15);; */
}

/* .user-profile{
  width: 96px;height: 96px;
   display: flex; 
   flex-wrap: wrap;
  text-align: center;border-radius: 48px;overflow: clip;
} */
.user-profile {
  /* width: 96px;
  height: 96px;
  display: flex;
  position: relative;
  border-radius: 48px;
  overflow: clip;
  justify-content: center; */
  display: inline-block;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  left: 30px;
  position: fixed;
  overflow: hidden;
}

.user-profile .img-fluid {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.user-profile-device .img-fluid {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.p-0 {
  padding: 0 !important;
}

/* Nav */
.navbar-cus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*background-color: #000;*/
  position: fixed;
  width: 80%;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 1050;
  top: 30px;
  left: 23%;
  right: 0;
}

.navbar-cus-system {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  position: fixed;
  width: 80%;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 1050;
  top: 30px;
  left: 66px;
  right: 0;
}

.btn-back {
  background-color: none;
  background: none;
  color: #fff;
  border: none;
  font-size: 20px;
  padding: 14px 0;
  display: flex;
  align-items: center;
}

.btn-back-arrow {
  width: 8px;
  margin-right: 16px;
}

.btn-next-arrow {
  width: 48px;
}

.title-center {
  width: 80%;
  text-align: center;
  color: #0A84FF;
  position: relative;
  right: 13.5%;
}

.title-center-device {
  width: 80%;
  text-align: center;
  color: #0A84FF;
  position: relative;
  right: 25.5%;
}

/* inner wrapper  */
.inner-wrapper {
  display: flex;
  margin: 0px auto 0;
  flex-direction: column;
  width: 1230px;
  padding: 0 0px;
  height: 100vh;
  justify-content: center;
}

.inner-wrapper.table-top {
  justify-content: start;
  padding-top: 12%;
  width: 100%;
}

.cus-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 14px;
  padding-top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cus-row:hover {
  cursor: pointer;
}

.col-form-label {
  font-size: 25px;
  font-weight: 500;
}

.btn-row {
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
  padding-bottom: 24px;
  /* padding-top: 1.5rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brd-btm {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.col-sidebar {
  background: #282828;
}